import AddToCart from '@theme/components/cart/AddToCart'
import AppTitle from '@theme/components/atom/AppTitle'
import Modal from '@theme/components/utils/Modal'
import ProductAvailability from '@theme/components/product/ProductAvailability'
import ProductMixin from '~/mixins/ProductMixin'

export default {
  components: {
    AddToCart,
    AppTitle,
    Modal,
    ProductAvailability,
  },
  mixins: [ProductMixin],
  props: {
    loading: Boolean,
    product: {
      type: Object,
      required: true,
    },
    variant: {
      type: Object,
      default: null,
    },
  },
  methods: {
    hide() {
      this.$refs.modal.hide()
    },
    show() {
      this.$refs.modal.show()
    },
  },
}
